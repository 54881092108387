// The default meta title
export const metaTitle = "StatLink - SPORTS ANALYTICS. ALL IN ONE PLACE";

export const homePageName = "";
export const socialLinkPageTitle = "Link you social media";
export const subscriptionPageTitle = "Subscriptions";
export const myTestPageTitle = "My Test";
export const personalityTrait = "Personality Trait Instrument";

// Meta title for login page
export const loginMetaTitle = "Login";
export const resetPasswordTitlle = "Reset your password";

// Meta title for sign up page
export const signUpMetaTitle = "Sign Up";
export const registrationMetaTitle = "Registration";

export const setupTeamMetaTitle = "Setup your team";

// Meta title for forgot password page
export const forgotPasswordMetaTitle = "Forgot Password";

// Success message on forgot password page
export const forgotPasswordEmailSent =
  "Password reset email has been sent successfully.";

// Error message on forgot password page (unknown error)
export const forgotPasswordError =
  "We were unable to send the password reset email, please try again.";

// Error message on forgot password page (email not found)
export const forgotPasswordEmailNotFoundError =
  "We could not find an account associated with the email provided, please check the email and try again.";

// Meta title for reset password page
export const resetPasswordMetaTitle = "Reset Password";

// Success message on reset password page
export const resetPasswordSuccess = "Your password has been changed";

// Error message on reset password page when passwords do not match
export const resetPasswordsDontMatch = "Passwords do not match";

// Error message on reset password invalid token
export const resetPasswordsInvalidToken = "This link is not valid";

// Navbar buttons text
export const navbarSignOutButton = "Sign Out";

// Greeting for returning users above dashboard search
export const dashboardGreetingGuest = "Welcome :)";
export const welcomeToLabel = "Welcom to";

export const managerLabel = "Manager";
export const playerLabel = "Player";
export const nextLabel = "Next";
export const skipLabel = "Skip";
export const fNameLabel = "First Name";
export const lNameLabel = "Last Name";
export const whatIsYourSport = "What is your sport?";

// nav login label
export const emailLabel = "Email";
export const passwordLabel = "Password";
export const logInLabel = "Log In";
export const logoutLabel = "Logout";
export const forgotEmailOrPass = "Forgot Password or Email?";
export const newToStatlink = "New to StatLink?";
export const getStarted = "Create Account";
export const resetPasswordLink = "Send Reset Password Link";
export const dontKnowMyEmail = "I don't know My StatLink email";
export const forgotEmail = "Forgot Email";
export const emailSupport = "Email Support";
export const emailSupportDesc =
  "Please email support@dsa-labs.com for help accessing your account.";
export const notValidInvitationUrl = "This link is not valid to join the team";
export const invitedToJoinString = (role: string) => {
  return !!role
    ? `You have been invited to join this team as a ${role}.`
    : notValidInvitationUrl;
};

export const promotionEmailLabel = "Receive Promotional Emails";

// nav sign up label
export const registrationLabel = "Registration";
export const registerLabel = "Register";
export const signUpLabel = "Sign Up";
export const whoAreYou = "Who are you?";
export const askEmail = "What is your email address?";
export const confirmEmail = "Confirm Email Address";
export const managerRegDesc =
  "Assemble a team and create datasets that translate into on-field success.";
export const playerRegDesc =
  "Access your profile analytics, and feed data into it to track your development.";
export const enterPassword = "Enter a password";
export const askName = "What is your name?";
export const askDob = "What is your date of birth?";
export const uploadProfileImage = "Upload a profile photo";
export const removeImage = "Remove Image";

export const sendInviteLabel = "Send Invite";
export const signupBegin = "You have been invited to join";
export const signupEnd =
  "By clicking Sign Up, you will join the team with this account:";
export const signupPlayerDesc =
  "You will be able to access your profile analytics, and feed data into it to track your development.";

export const backLabel = "Back";
export const goBackLabel = "Go back to";

export const pageNotFoundMessage = "Page not found.";
export const errorRefreshMessage =
  "This is embarrassing, an error has occured.";
export const errorRefreshButtonTitle = "Refresh";
export const errorRetryMessage = (resourceName = "page") =>
  `failed to fetch ${resourceName}`;
export const errorRetryButtonTitle = "Retry";
export const contactSupportLabel = "Contact Support";

export const setupTeamLabel = "Set up team";
export const completeLabel = "Complete";
export const verifyYourAccountLabel = "Verify you email";
export const verifyPlayerLabel = "Verify Player";
export const regCompleteDesc = (val: string) =>
  `We just sent a verification email to ${val}. Please go to your inbox and verify your account after setting up a team.`;
export const yesJoinTeamLabel = "Yes, Join Team";
export const noCreateTeamLabel = "No, Create Team";

export const yesConfirmLabel = "Yes I'm Sure";
export const noGoBackLabel = "No, Go Back";

export const enterYourLabel = "Enter Your";
export const teamNameLabel = "Team name";
export const uploadTeamLogoLabel = "Upload team logo";
export const teamSetupTitle = "Your team is all set up.";
export const teamSetupDesc =
  "You can now access this team and set up your roster when you log in.";
export const goToDashboardLabel = "Go to dashboard";
export const joinATeamLabel = "Join a team";
export const joinTeamLabel = "Join Team";
export const teamIdLabel = "Team ID";
export const joinWithIdLabel = "Join with id";
export const joinTeamDesc =
  "Managers can find the Team ID in Team Management settings.";
export const joinLaterLabel = "Don't know ID, Join Later";
export const declineLabel = "Decline Invitation";

export const shareYourProfile = "Share your profile";
export const copyLink = "Copy Link";
export const shareLabel = "Share";
export const filterLabel = "Filter";
export const shareWithColleges = "Share with colleges"
export const socialShareDesc = "Check out my FlashStat!";
export const shareFlashStatLabel = "Share FlashStat on";
export const facebookLabel = "Facebook";
export const twitterLabel = "X";
export const instaLabel = "Instagram";

export const addClipLabel = "Add Clip";
export const importLinkLabel = "Import Link";
export const videoClipLabel = "video clip";
export const clipUploadMessage =
  "Drag and drop a file you want to upload. File size limit 120 Mb.";
export const clipUploadError =
  "File too Big, please select a file less than 120Mb";
export const clipAllowedFormatMsg = (formats: string[]) => {
  return (
    <>
      format must be{" "}
      {formats
        .map((f, index) => {
          return index === formats.length - 1 ? `or .${f}` : `.${f}`;
        })
        .join(", ")}
    </>
  );
};
export const clipAllowedLimitExceed = (limit: number) =>
  `You can only upload up to ${limit} videos!`;
export const uploadsLabel = "uploads";
export const remainingLabel = "remaining";

export const saveLabel = "Save";
export const cancelLabel = "Cancel";
export const sendLabel = "Send";
export const dateLabel = "Date";
export const titleLabel = "Title";
export const descLabel = "Description";
export const disconnectLabel = "Disconnect";
export const dateFieldPlaceholder = "MM/DD/YYYY";
export const titleFieldPlaceholder = "Type your title here";
export const descFieldPlaceholder = "Add a description";
export const videLinkLabel = "Video link";
export const videLinkPlaceholder = "https://www.";
export const oopsLabel = "OOPS!";
export const videoUploadErrorMessage = "OOPS! Someting wrong with upload clip.";
export const processingLabel = "Processing...";
export const editClipLabel = "Edit Clip";
export const addLabel = "Add";
export const editLabel = "Edit";
export const clipLinkLabel = "Clip Link";
export const videoRemoveTitle = "Removing Highlight Clip";
export const videoRemoveDetails = (title: string) => (
  <>
    Are you sure you wish to remove {title}? You will no longer be able to see
    this highlight clip
  </>
);

export const profileLabel = "Profile";

export const footerMenuItems = {
  editProfile: "Edit Profile",
  linkMedia: "Link Social Media",
  subscription: "My Subscription",
  contactDSA: "Contact DSA Labs",
  teamManagement: "Team Management",
  changePassword: "Change Password",
  store: "Store",
  myTests: "My Tests",
  logOut: "Logout",
  privacyPolicy: "Privacy Policy",
  integrations: "Integrations",
  following: "Following",
};

export const socialLinkLabel = "Link Social Media";
export const selectPlatformLabel = "Select a Platform";
export const linkToLabel = "Link to";
export const usernameOrProfileLabel = "username or profile";
export const atUsernameLabel = "@username";
export const connectLabel = "Connect";
export const upgradeLabel = "Upgrade";
export const cancelSubscriptionLabel = "Cancel Subscription";
export const myPlanLabel = "My Plan";
export const planLabel = "Plan";
export const billingStatusLabel = "Billing Status";
export const expirationLabel = "Expiration";
export const expiredLabel = "Expired";
export const confirmLabel = "Confirm";
export const subDeleteDesc =
  "Are you sure you wish to cancel your subscription? You will still be able to use your plan up to the end of the billing period.";

export const packageTitleInfo = "Recurring billing, cancel anytime.";
export const packageTitle = "Upgrade Your Plan";
export const monthlyLabel = "Monthly";
export const annuallyLabel = "Annually";
export const continueLabel = "Continue";
export const okLabel = "Ok";
export const lockedLabel = "Locked";
export const featureDisabledDesc =
  "This feature is disabled. Stay tuned for its release!";

export const contactDsaLabel = "Contact DSA Labs";
export const privayPolicyLabel = "Privacy Policy of DSA Labs, Inc.";
export const contactDsaDesc =
  "Have an issue with the app? Need some help with something? Have an idea for us? Contact us by filling out the form below!";
export const subjectLabel = "Subject";
export const msgSubjectLabel = "Message Subject Line";
export const msgSubjectPlaceholder = "What is the problem?";
export const msgLabel = "Message";
export const enterDetailHereLabel = "Enter details here...";
export const submitTicketLabel = "Submit Ticket";

export const changePasswordLabel = "Change Password";
export const currentPasswordLabel = "Current Password";
export const newPasswordLabel = "New Password";
export const cNewPasswordLabel = "Confirm New Password";
export const confirmPassword = "Confirm Password";
export const currentPasswordHelp = "Enter your current password.";
export const newPasswordHelp = "Enter a new password and verify it.";
export const submitLabel = "Submit";

export const teamManagementLabel = "Team Management";
export const addTeamLabel = "Add Team";
export const playerSubmissionsLabel = "Player Submissions";

export const pendingLabel = "Pending";
export const teamAccessPendingDesc =
  "You will gain access to this team once a manager accepts your request.";

export const noTeamLabel = "You have no teams.";
export const addTeamNowLabel = "Add a team now!";

export const teamInfoLabels = {
  teamName: "Team Name",
  club: "Club",
  city: "City",
  state: "State",
  teamId: "Team ID",
  leaveTeam: "Leave Team",
  leagueLabel: "League",
  divisionLabel: "Division",
  leaveTeamMsg: (teamName: string) =>
    `Are you sure you wish to leave ${teamName}? Your managers will no longer be able to contribute to your FlashStat.`,
};

export const removeCoachLabels = {
  titie: "Remove User",
  desc: (name: string, team: string) =>
    `Are you sure you wish to remove ${name} from ${team}? They will no longer have access to this team's data.`,
};

export const storeLabel = "Store";
export const purchaseLabel = "Purchase";
export const quantityLabel = "Quantity";
export const enterQtyLabel = "Enter Quantity";

export const myTestsLabel = "My Tests";
export const purchaseTestNowText = "Purchase a test now!";
export const noTestToCompleteText = "You have no tests to complete.";
export const noCompletedText = "You have no completed tests.";
export const purchaseTestLabel = "Purchase a New Test";
export const createNewTestLabel = "Create New Test";
export const createdByLabel = "Created By";
export const expiresLabel = "Expires";
export const completedLabel = "Completed";
export const createdLabel = "Created";
export const dashboardLabel = "Dashboard";

export const paymentStatusPageTitle = "Payment Status Page";

export const successLabel = "Success";
export const paymentSuccessDesc = (name: string) =>
  `You have successfully purchased ${name}. Your order reference is`;
export const paymentSuccessDesc1 = () => (
  <>
    Please contact{" "}
    <a href="mailto:support@dsa-labs.com">
      <b>
        <u>support</u>
      </b>
    </a>{" "}
    with this order reference if you have any issues.
  </>
);
export const returnToDashboardLabel = "Return to Dashboard";
export const incompleteLabel = "Incomplete";
export const incompleteTestDesc =
  "You can not submit your responses until you have completed the test.";
export const testSuccessTitle = "Your reponse has been submitted!";
export const testSuccessDesc =
  "You can access your results from your dashboard.";
export const resultsLabel = "Results";
export const testCompltedOnLabel = "Test completed on";
export const testResultDesc = (name: string) =>
  `These are the results for the ${name}. Understand the meaning of these results by clicking on each category for more details.`;

export const isActiveLabel = "Active on Team";
export const jerseyNumberLabel = "Jersey Number";

export const deletePlayerDesc = (name: string) =>
  `Are you sure you would like to remove ${name}? You will no longer be able to access their data.`;
export const noLabel = "No";
export const yesRemoveLabel = "Yes, Remove";

export const emptyRosterDesc = "Your roster is empty";
export const emptyRosterManagerDesc = "There are no managers on this team.";
export const addPlayerNowDesc = "Upload your roster with a QR Code, import a spreadsheet, or add players manually.";
export const addPlayerLabel = "Add Player";
export const filterRolsterLabel = "Filter Roster";
export const updateLabel = "Update";
export const playerQRLabel = "View Player Invitation Code";
export const managerQRLabel = "View Manager Invitation Code";

export const createYourOwnText = (name: string) => `Create Your Own ${name}`;
export const filterLibraryLabel = "Filter Library";

export const customScoreCardLabel = "Custom Scorecard";
export const editScoreCardLabel = "Edit Scorecard";
export const buildYourScorecard = "Build your scorecard.";
export const nameOfMetricLabel = "Name of metric";
export const isLowScoreBestLabel = "Low score is best?";
export const noRankingsLabel = "No rankings";
export const defaultZeroLabel = "Default to 0";

export const sourceLabel = "Source";
export const objectiveLabel = "Objective";
export const setupLabel = "Setup";
export const instructionsLabel = "Instructions";
export const measurementLabel = "Measurement";
export const describeScoreCard = "Finally Name and Describe your scorecard";
export const scoreCardNameLabel = "Scorecard Name";
export const createScoreCardLabel = "Create Scorecard";
export const optionalImageLabel = "Optional: Add an image";
export const saveChangesLabel = "Save Changes";
export const confirmationLabel = "Confirmation";
export const createDatasetLabel = "Create Dataset";
export const characteristicLabel = "Characteristic";
export const deleteMetricDesc =
  "Are you sure you wish to delete this metric? Historical data will not be lost.";

export const archiveLabel = "Archive";
export const delteScoreCardDesc = "Are you sure you would like to archive";
export const deleteScoreCardNote =
  "Any previous collected data will not be deleted.";

export const customDatasetLabel = "Custom Dataset";
export const createDataSetHeadings = [
  "What type of data are you collecting?",
  "What characteristic are you targeting?",
  "How should this data be measured?",
  "Does a higher score indicate better performance?",
  "Finally Name and Describe your dataset",
];

export const distributionTestLabel = "Distribute Test";
export const distributionTestDesc =
  "Send this test to players by copying the link below.";
export const copyLinkLabel = "Copy Link";
export const startDateLabel = "Start Date";
export const endDateLabel = "End Date";
export const responsesLabel = "Responses";

export const underStandTestResultLabel = "Understanding Team results";
export const underStandTestResultDesc =
  "Understand what the test results mean for your team and how you can adjust your coaching method based off each players score.";
export const viewTestResultLabel = "View Team Results";
export const copiedLabel = "Copied!";

export const responsesZeroStateTitle = "There are no responses";
export const responsesZeroStateDesc =
  "Results will populate once players complete the test";
export const deleteTestLabel = "Delete Test";
export const deleteTestDesc =
  "Are you sure you wish to delete this test? You will delete your players' responses and any analytics tied to this test.";
export const deleteResponseLabel = "Delete Response";
export const deleteResponseDesc =
  "Are you sure you wish to delete this test response?";
export const testNotHaveResponseDesc = "This test does not have any responses.";

export const ptiManagerScreenDesc =
  "Get a better understanding of your players' personalities.";
export const errorLabel = "Error";

export const overallRankLabel = "Overall Ranking";
export const topPlayerLabel = "Top Players";

export const lastSubmissionLabel = "Last Submission";
export const generateTeamReportLabel = "Generate Team Report";
export const submissionLabel = "Submission";
export const getSubmissionErrorDesc =
  "Unable to retrieve data for this submission.";
export const editSubmissionLabel = "Edit Submission";
export const submissionNameLabel = "Submission Name";
export const notesLabel = "Notes";
export const warningLabel = "Warning";
export const deleteSubmissionDesc =
  "Are you sure you wish to delete the following submission? This data will be permanently removed from the system.";
export const deleteLabel = "Delete";
export const deleteAccountMessage =
  "To confirm you decision, type your password in the box above and then click Confirm.";
export const deleteAccountWarning = "THIS ACTION IS NOT REVERSABLE!";

export const noticeLabel = "Notice";
export const dataCollectionSessionDesc =
  "You have a draft from your previous session. Would you like to continue with this session or start new?";
export const doneLabel = "Done";
export const yesContinue = "Yes, continue";
export const noCreateSession = "No, create a new session";
export const finishLabel = "Finish";

export const noPlayerLabel = "No Players";
export const noPlayerDesc =
  "You can not proceed without players on your team. You can add more players in the Roster tab.";
export const playerNoteLabel = "Player Note";
export const playerNotePlaceholder = "Leave a note for this player.";
export const playerCanSeeLabel = "Player can see this note";

export const changeTimeLabel = "Change Time";

export const confirmDataCollectionDesc =
  "Are you sure you wish to finish this session? Your data will be uploaded and the session will conclude.";

export const overallLabel = "Overall";
export const fireLabel = "Fire";

export const premiumFeatureLabel = "Premium Feature";
export const checkItOutLabel = "Check it out";
export const uploadMoreClipsDesc =
  "Go Premium and unlock the ability to upload more highlight clips!";
export const premiumUnloackMoreData =
  "Go Premium and unlock access to ALL of your data!";
export const premiumUnloackFlashStat =
  "Go premium and share your FlashStat with the world!";
export const premiumUnloackMoreOptions =
  "You have reached your custom evaluation card limit. Upgrade your team to increase your limit.";

export const submissionslabel = "Submissions";
export const untitledSubmissionLabel = "Untitled Submission";
export const subscribeLabel = "Subscribe";
export const activeLabel = "Active";

export const premiumLineup = "Upgrade your team to create and share lineups.";
export const premiumTeamSubscription =
  "Upgrade your team to access all of your submissions.";
export const premiumTrendsEvaluation =
  "Upgrade your team to access Player Trends.";
export const premiumTrendsViewReport = "Upgrade your team to generate reports.";
export const premiumTimeStampUpgrade =
  "Upgrade your team to Premium to access Timestamps.";

export const getStargedWithSubmissions =
  "Visit our Evaluation Card Library to get started with your first submission.";
export const characteristicsWithoudData =
  "Submit an evaluation with this characteristic to see player rankings.";
export const followersLabel = 'Followers';
export const followLabel = 'Follow';
export const followingLabel = 'Following';
export const followLoginAlertDesc = (isTeam: boolean) => `You must be logged in to follow/unfollow a ${isTeam ? 'team' : 'profile'}.`;
export const followRegisterDesc = `You must be logged in to register a profile.`;
export const followClaimProfileDesc = `You must be logged in to claim ownership of a profile.`;
export const followContactDesc = `You must be logged in to contact owner of the profile.`;
export const followClaimOwnershipDesc = () => (
  <>
    Would you like to request ownership of this profile? The owners of this profile will be notified of your request. If there are no owners, then StatLink support at{" "}
    <a href="mailto:support@dsa-labs.com">
      <b>
        <u>support@dsa-labs.com</u>
      </b>
    </a>{" "}
    will be notified of your request.
  </>
);
export const archivedDesc = () => (
  <>
    This profile has been archived. Please contact us at{" "}
    <a href="mailto:support@dsa-labs.com">
      <b>
        <u>support@dsa-labs.com</u>
      </b>
    </a>
  </>
);

export const followContactPlayerSubText = 'The message will be sent to the owners of the profile.';
export const submitEntryDesc = "Are you sure you want to leave without submitting your entry?";
export const selectedCoaches = "Selected Coaches";
export const selectSchoolAndCoach = "Select Schools and Coaches";
export const Search = "Search";
export const allDivision = "All Divisions";
export const allState = "All States";
export const selectDivision = "Select Division";
export const selectState = "Select State";
export const selectAllLabel = "Selected Email";
export const removeAllLabel = "Remove all";
export const ImportantLabel = "Important";
export const subscribeNote = "Please subscribe to unlock access to this feature ";
export const fiveEmailAllowed = "You have reached weekly email limit";
export const emailNote1 = `Please use $[name] to personalize the coach's name. For Example, Dear $[name] will display "Dear, John Smith."`;
export const emailNote2 = "A maximum of 5 emails can be sent in one week with a premium account.";
export const emailNote3 = "A maximum of 3 emails can be sent in lifetime with a free account.";
export const emailNote4 = "Please review and edit elements marked in red.";
export const errorMsg = "Something went wrong";
export const confirmEmailAlert = "Are you sure you want to send email?";
export const Coachesnotfound = "Coaches not found";
export const selectType = "Select Team Gender";
export const maleLabel = "Men";
export const femaleLabel = "Women";
export const allTypeLabel = "All Team Genders";
export const selectSports = "Select Sport";
export const allSports = "All Sports";
export const noPhoneNumber = "No Phone Number";
export const toolbarHome = "Home";
export const toolbarAnalysis = "Analysis";
export const toolbarDataSets = "Data Entry";
export const toolbarSearch = "Search";
export const toolbarProfile = "Account";
export const academicGPA = "GPA";
export const academicSAT = "SAT";
export const academicGRE = "GRE";
export const academicACT = "ACT";
export const academicFieldOfStudy = "Field Of Study";
export const academicMajor = "Major";
export const academicNcaaEligible = "NCAA Eligible";
export const academicNcaaYears = "Years of NCAA Eligibility Remaining";
export const academicInterests = "Academic Summary";
export const academicInformation = "Academic Information";
export const academicEmpty = "There are no academic Information for this profile.";

export const tooltipScale1to5 = "This is the player's score based off of a pre-defined scale 1 to 5.";
export const tooltipScale1to4 = "This is the player's score based off of a pre-defined scale 1 to 4.";
export const tooltipScale1to3 = "This is the player's score based off of a pre-defined scale 1 to 3.";
export const tooltipCounter = "Total number of occurrences.";
export const tooltipPosValue = "This is the player's measured distance, time, or other recorded value.";
export const tooltipMultiOutcome = "The breakdown of the total number of occurrences of this metric.";
export const tooltipPlayerRanking = "This is the player’s StatLink Ranking.";

export const dialogScale = "The score that was recorded for the player based on the pre-defined scale.";
export const dialogCounter = "The total number of occurrences recorded during the session corresponding to this submission.";
export const dialogPosValue = "This is the player's measured distance, time, or other recorded value.";

export const dateFormatLabel = "mm/dd/yyyy";
export const mDYyyyDateFormatLabel = "M/d/yyyy";

