import varsConfig from './config';

const config = varsConfig();

export const NODE_ENV = process.env.NODE_ENV;
export const API_BASE_URL = config.REACT_APP_API_BASE_URL;
export const PLAYER_SOCIAL_LINK_DOMAIN = config.REACT_APP_PLAYER_SOCIAL_LINK_DOMAIN;
export const DOMAIN_BASE_URL = config.REACT_APP_DOMAIN_BASE_URL;
export const REPORT_VIEW_DOMAIN = config.REACT_APP_REPORT_VIEW_DOMAIN;
export const WS_URL = config.REACT_APP_WS_URL;

export const API_URI = {
  LOGIN: '/login',
  FORGOT_PASSWORD: '/post/reset/password',
  REGISTER: '/signup',
  VERIFY_EMAIL: '/signup/verifyEmail',
  // GET_SPORTS: '/info/sports',
  // GET_USER: '/info/user',
  // VERIFY_TEAM_NAME: '/signup/verifyTeamName',
  // ADD_TEAM_MEMBER: '/post/request/join/user',
  // PLAYER_PROFILE: '/info/player/analytics/profile',
  // PLAYER_TEAM_PROFILE: '/info/team/player/analytics/profile',
  PLAYER_CLIP: '/info/player/clip',
  PLAYER_CLIP_ADD: '/post/add/player/clip',
  PLAYER_CLIP_EDIT: '/post/edit/player/clip',
  PLAYER_CLIP_DELETE: '/post/delete/player/clip',
  PLAYER_UPDATE_PROFILE: '/post/edit/profile',
  // DELETE_PROFILE: '/account/delete',
  SOCIAL_LOOKUP: '/social/lookup',
  SOCIAL_AUTH: '/social/auth',
  // SUBSCRIPTION: '/info/subscription',
  PACKAGES: '/info/packages',
  CANCEL_PACKAGES: '/cancelPackage',
  CANCEL_PACKAGES_TEAM: '/cancelPackageTeam',
  CONTACT_US: '/post/ticket/submit',
  CHANGE_PASS: '/post/edit/password',
  TEAMS: '/info/teams',
  TEAM: '/info/team',
  TEAM_V1: '/team',
  TEAM_CARDS: '/team/cards',
  TEAM_DATASET_TREND: '/team/dataset/trend',
  // UPDATE_TEAM: '/post/edit/team',
  GET_TEAM_ID: '/team/id',
  DELETE_TEAM: '/post/leave/team',
  DELETE_TEAM_MANAGER: '/post/remove/manager',
  STORE_FEATURES: '/info/features',
  CONFIRM_PAYMENT: '/configPayment',
  PLAYER_TESTS: '/tests/player',
  SCORECARD_TESTS: '/info/scorecard/tests',
  SCORECARD_TEST: '/info/scorecard/test',
  CHECKOUT_ORDER: '/checkout/order',
  CHECKOUT: '/checkout',
  TEST_QUESTIONS: '/info/scorecard/test/questions',
  TEST_RESULT: '/info/scorecard/test/results',
  TEST_DELETE: '/post/delete/test',
  SUBMIT_TEST_RESPONSE : '/post/add/test/response',
  DELETE_TEST_RESPONSE : '/post/delete/test/response',
  PAYMENT_STATUS: '/feature/order',
  PLAYER_EDIT: '/post/edit/player',
  PLAYER_ADD: '/team/roster/player',
  PLAYERS_ADD: '/post/add/players',
  PLAYER_DELETE: '/post/delete/player',
  // TEAM_PROFILES: '/info/team/analytics/profile',
  // TEAM_SCORECARD: '/info/scorecards',
  // TEAM_DATASETS: '/info/datasets',
  // CREATE_SCORECARD: '/post/add/scorecard',
  // EDIT_SCRORECARD: '/post/edit/scorecard',
  // UPDATE_DATASET: '/post/edit/data/hide',
  // DELETE_SCORECARD: '/post/archive/scorecard',
  // CREATE_DATASET: '/post/add/dataset',
  // EDIT_DATASET: '/post/edit/dataset',
  // DElETE_DATASET: '/post/archive/dataset',
  TEAM_ANALYTICS: '/team/analytics',
  // TEAM_CHARACTERSTICK: '/info/team/analytics/characteristic',
  TEAM_SUBMISSION: '/team/submission',
  TEAM_SUBMISSION_NOTE: '/team/submission/note',
  DELETE_SUBMISSION: '/post/delete/submission',
  // ROSTER: '/info/team/roster',
  GET_SCORECARD: '/info/scorecard',
  SAVE_SCORECARD: '/submission',//'/post/add/data/scorecard',
  // UPDATE_BIO: '/post/add/playerbio',
  FLASHSTAT_FEATURED: '/flashstat/featured',
  SUBMISSIONS: '/submissions',
  SIGNUP: '/team/signup',
  SUBMISSIONS_DETAIL: '/submission',
  // TEAM_LINEUP: '/info/team/lineup',
  TEAM_LINEUP_URL: '/team/lineup',
  // TEAM_HOME: '/info/team/home',
  SCORECARD_ANALYTICS: '/info/team/analytics/scorecard',
  TEAM_ROSTER: '/team/roster',
  TEAM_ROSTER_INVITE: '/team/roster/invite',
  TEAM_REMOVE_MANAGER: '/post/remove/manager',
  TEAM_INVITE_ACCEPT: '/team/roster/invite/accept',
  SCORECARD_SHEET_UPLOAD: '/post/add/data/scorecard/spreadsheet',
  SCORECARD_SHEET_UPLOAD_CHUNK: '/post/add/data/scorecard/spreadsheet/section',
  SCORECARD_UPDATE_ANALYTICS: '/post/submission/update/analytics',
  ACCOUNT_VALIDATE: '/account/validate',
  RESET_PASS_CONFIRM: '/post/reset/password/confirm',
};

export const NEW_API_URI = {
  ROLES: '/roles',
  ACCOUNT: '/account',
  PLAYER: '/player',
  PLAYERS: '/players',
  SPORTS: '/sports',
  TEAM_PLAYER: '/team/player',
  PLAYER_BIO: '/player/bio',
  PLAYER_OWNER: '/player/owner',
  TEAM_ANALYTICS: '/team/analytics',
  PLAYER_SUBSCRIPTION: '/subscription/player',
  TEAM_SUBSCRIPTION: '/subscription/team',
  TEAM: '/team',
  TEAM_ROSTER: '/team/roster',
  CHECKOUT: '/checkout',
  TEAM_LOGO: '/team/logo',
  CARDS: '/cards',
  PLAYER_PHOTO: '/player/photo',
  PLAYER_SUB: '/subscription/player',
  TEAM_SUB: '/subscription/team',
  PLAYER_TESTS: '/player/tests',
  INTEGRATION: '/integration',
  SCORECARD: '/card',
  SEARCH: '/search',
  NOTIFICATIONS: '/notifications',
  NOTIFICATION: '/notification',
  OWNERSHIP: '/request/ownership',
  CONTACT: '/contact',
  REGISTER_PROFILE: '/request/registration',
  ACCOUNT_FOLLOWING: '/account/following',
  SUBMISSION_SYNC: '/submission/sync',
  GET_DIVISIONS: '/get-divisions',
  GET_SPORTS: '/get-sports',
  GET_STATES: '/get-states',
  FILTER_COACH: '/filter-coach',
  SEND_EMAIL:'/send-email',
  SEND_EMAIL_LIMIT:'/send-limited-email',
  ADD_ACADEMIC_INFO:'/academic-info/create-update',
  GET_COLLEGE_LIST:'/colleges',
  GET_Majors_LIST:'/majors',
  GET_DYNAMIC_BOX_SCORE_PLAYERS_V1: '/info/team/analytics/v1/dynamicBoxScore'
  // TEAM_INVITE_PLAYER_ACCEPT: '/team/roster/player',
}

export const ROUTES = {
  HOME: '/',
  LOGIN: '/login',
  REGISTER: '/register',
  // SIGNUP: '/signup',
  FORGOT_PASSWORD: '/forgotPassword',
  RESET_PASSWORD: '/reset/password',
  SETUP_TEAM: '/setupTeam',
  PROFILE: '/profile',
  UPLOAD_CLIP: '/uploadClip',
  EDIT_PROFILE: '/editProfile',
  ADD_EDIT_PLAYER: '/addEditPlayer',
  EDIT_BIO: '/editBio',
  LINK_MEDIA: '/linkMedia',
  SUBSCRIPTION: '/subscription',
  CONTACT_US: '/contactUs',
  CHANGE_PASS: '/changePass',
  TEAMS: '/teams',
  EDIT_TEAM: '/editTeam',
  STORE: '/store',
  MY_TEST: '/myTest',
  TEST_RESULT: '/test/result',
  PAYMENT_STATUS: '/order',
  PTI_TEST: '/test',
  ROSTER: '/roster',
  DATA_SETS: '/dataSets',
  ANALYSIS: '/analysis',
  SUBMISSION: '/submission',
  SETTINGS: '/settings',
  SUBMISSIONS: '/submissions',
  DATA_COLLECT: '/dataCollect',
  EDIT_SUBMISSION: '/editSubmission',
  FLASHSTAT: '/flashstat',
  FLASHSTATTEAM: '/flashstat/team',
  LINEUP: '/lineup',
  ANALYSIS_SEARCH_PARAM: {
    CHAR: 'characteristic',
  },
  SCORECARD: '/scorecard',
  TEAM_SIGNUP: '/teamSignup',
  PRIVACY_POLICY: '/privacyPolicy',
  INTEGRATIONS: '/integrations',
  NOT_FOUND: '/404',
  SEARCH: '/search',
  NOTIFICATIONS: '/notifications',
  FOLLOWING: '/following',
};

export const SOCIAL_SHARE = {
  FACEBOOK: 'https://www.facebook.com/sharer/sharer.php?u=',
  TWITTER: 'https://twitter.com/intent/tweet?url=',
};

export const FILE_UPLOAD = {
  ALLOWED_LIMIT: 10,
  MAX_FILE_SIZE: 120,
  FORMATS: ['mp4', 'mov', 'mwv', 'avi', 'flv', 'h264-mov', 'm4v'],
  IMG_FORMATS: ['jpeg', 'jpg', 'png'],
  IMG_MAX_FILE_SIZE: 120,
};
